import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React from "react";
import { LEGALS_CONTENT_SECTION } from "../../../util/constants";
import Form from "./Form";
import { classes, StyledSectionWrapper } from "./styles";
import UsefulCard from "./UsefulCard";

const Content = () => {
  return (
    <StyledSectionWrapper sectionId={LEGALS_CONTENT_SECTION}>
      <Container>
        <Grid container>
          <Grid
            container
            item
            sm={12}
            md={6}
            justifyContent="flex-start"
            direction="column"
            className={classes.column}
          >
            <Typography
              variant="h6"
              align="left"
              className={clsx(classes.marginBottom, classes.title)}
            >
              Informations pratiques
            </Typography>

            <UsefulCard />
            <div className={classes.imageWrapper}>
              <img
                className={classes.image}
                src="/images/innovance-solutions-nous-contacter.svg"
                alt="Nous contacter"
                width="843"
                height="573"
              />
            </div>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={6}
            justifyContent="flex-start"
            direction="column"
            className={classes.column}
          >
            <Typography
              variant="h6"
              align="left"
              className={clsx(classes.marginBottom, classes.title)}
            >
              Nous écrire
            </Typography>

            <Form />
          </Grid>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Content;
