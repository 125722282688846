import { OpeningHoursDay, OpeningHoursHalfDay } from "./types";

const BASE_MORNING: OpeningHoursHalfDay = {
  startHour: 9,
  startMinutes: 0,
  endHour: 12,
  endMinutes: 0,
};
const BASE_AFTERNOON: OpeningHoursHalfDay = {
  startHour: 13,
  startMinutes: 30,
  endHour: 18,
  endMinutes: 0,
};

export const openingDays: { [key: number]: OpeningHoursDay } = {
  1: {
    day: "Lundi",
    morning: BASE_MORNING,
    afternoon: BASE_AFTERNOON,
  },
  2: {
    day: "Mardi",
    morning: BASE_MORNING,
    afternoon: BASE_AFTERNOON,
  },
  3: {
    day: "Mercredi",
    morning: BASE_MORNING,
    afternoon: BASE_AFTERNOON,
  },
  4: {
    day: "Jeudi",
    morning: BASE_MORNING,
    afternoon: BASE_AFTERNOON,
  },
  5: {
    day: "Vendredi",
    morning: BASE_MORNING,
    afternoon: {
      ...BASE_AFTERNOON,
      endHour: 16,
    },
  },
};
