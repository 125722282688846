import Avatar from "@mui/material/Avatar";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, { useCallback } from "react";
import { MdPhone } from "react-icons/md";
import useOpeningDayHours from "../../../../hooks/useOpeningDayHours";
import { PHONE_NUMBER } from "../../../../util/constants";
import { numberToDigit } from "../../../../util";
import { classes, Root } from "./styles";

const UsefulCard = () => {
  const { currentDay, isClosed, openingDays } = useOpeningDayHours();

  const renderRow = useCallback((dayNumber: number) => {
    const { day, afternoon, morning } = openingDays[dayNumber];
    const selected = day === currentDay?.day;
    return (
      <TableRow
        classes={{ selected: classes.rowSelected }}
        selected={selected}
        key={day}
      >
        <TableCell
          variant="footer"
          size="small"
          classes={{
            root: classes.tableCell,
            footer: clsx({ [classes.tableCellFooter]: selected }),
          }}
        >
          {day}
        </TableCell>
        <TableCell
          variant="footer"
          size="small"
          classes={{
            root: classes.tableCell,
            footer: clsx({ [classes.tableCellFooter]: selected }),
          }}
        >
          {`${numberToDigit(morning.startHour)}:${numberToDigit(
            morning.startMinutes
          )} - ${numberToDigit(morning.endHour)}:${numberToDigit(
            morning.endMinutes
          )}`}
        </TableCell>
        <TableCell
          variant="footer"
          size="small"
          classes={{
            root: classes.tableCell,
            footer: clsx({ [classes.tableCellFooter]: selected }),
          }}
        >
          {`${numberToDigit(afternoon.startHour)}:${numberToDigit(
            afternoon.startMinutes
          )} - ${numberToDigit(afternoon.endHour)}:${numberToDigit(
            afternoon.endMinutes
          )}`}
        </TableCell>
      </TableRow>
    );
  }, []);

  return (
    <Root>
      {/* <div className={clsx(classes.marginBottom, classes.avatarGroup)}>
        <Avatar
          className={clsx(classes.avatar, {
            [classes.avatarClose]: isClosed,
            [classes.avatarOpen]: !isClosed,
          })}
        >
          {isClosed ? <MdEventBusy /> : <MdEventAvailable />}
        </Avatar>
        <Typography
          variant="subtitle2"
          className={clsx(classes.typoAvailability, {
            [classes.typoClose]: isClosed,
            [classes.typoOpen]: !isClosed,
          })}
        >
          {isClosed
            ? "Nous sommes actuellement indisponibles, utilisez le formulaire de contact"
            : "Nous sommes actuellement disponibles"}
        </Typography>
      </div> */}
      <div className={clsx(classes.marginBottom, classes.avatarGroup)}>
        <Avatar className={classes.avatar}>
          <MdPhone />
        </Avatar>
        <Typography variant="subtitle1">{PHONE_NUMBER}</Typography>
        <Typography variant="caption"> (prix d'un appel local)</Typography>
      </div>
      {/* <div className={clsx(classes.marginBottom, classes.hours)}>
        <div className={clsx(classes.marginBottom, classes.avatarGroup)}>
          <Avatar className={classes.avatar}>
            <MdSchedule />
          </Avatar>
          <Typography variant="subtitle1">Assistance & Standard</Typography>
        </div>
        <TableContainer classes={{ root: classes.tableContainer }}>
          <Table aria-label="Innovance Solutions horaires de l'assistance et du standard téléphonique">
            <TableBody>
              {Object.keys(openingDays).map((dayNumber) =>
                renderRow(Number(dayNumber))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div> */}
    </Root>
  );
};

export default UsefulCard;
