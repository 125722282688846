import styled from "../../../../util/styled";

const PREFIX = "ContactUsefulCard";

export const classes = {
  marginBottom: `${PREFIX}-marginBottom`,
  avatarGroup: `${PREFIX}-avatarGroup`,
  avatar: `${PREFIX}-avatar`,
  avatarOpen: `${PREFIX}-avatarOpen`,
  avatarClose: `${PREFIX}-avatarClose`,
  typoAvailability: `${PREFIX}-typoAvailability`,
  typoOpen: `${PREFIX}-typoOpen`,
  typoClose: `${PREFIX}-typoClose`,
  hours: `${PREFIX}-hours`,
  tableContainer: `${PREFIX}-tableContainer`,
  rowSelected: `${PREFIX}-rowSelected`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellFooter: `${PREFIX}-tableCellFooter`,
};

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.avatarGroup}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: theme.palette.innoBlue.main,
    marginRight: theme.spacing(2),
  },

  [`& .${classes.avatarOpen}`]: {
    backgroundColor: theme.palette.success.main,
  },

  [`& .${classes.avatarClose}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.typoAvailability}`]: {
    fontWeight: 600,
  },

  [`& .${classes.typoOpen}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.typoClose}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.hours}`]: {},

  [`& .${classes.tableContainer}`]: {
    marginLeft: theme.spacing(7),
    width: "inherit !important",
  },

  [`& .${classes.rowSelected}`]: {
    backgroundColor: `${theme.palette.innoBlue.main} !important`,
  },

  [`& .${classes.tableCell}`]: {
    borderBottom: "none",
  },

  [`& .${classes.tableCellFooter}`]: {
    color: theme.palette.innoBlue.contrastText,
  },
}));
