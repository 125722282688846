import Button from "@mui/material/Button";
import styled from "../../../../util/styled";

const PREFIX = "ContactForm";

export const classes = {
  gutters: `${PREFIX}-gutters`,
  snackbarRoot: `${PREFIX}-snackbarRoot`,
  snackbarPaper: `${PREFIX}-snackbarPaper`,
  snackbarSuccess: `${PREFIX}-snackbarSuccess`,
  snackbarError: `${PREFIX}-snackbarError`,
  icon: `${PREFIX}-icon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const StyledForm = styled("form")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",

  [`& .${classes.gutters}`]: {
    margin: theme.spacing(1.5, 0),
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },

  [`& .${classes.snackbarRoot}`]: {
    left: "50%",
    right: "auto",
    transform: "translateX(-50%)",
  },

  [`& .${classes.snackbarPaper}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
  },

  [`& .${classes.snackbarSuccess}`]: {
    backgroundColor: theme.palette.success.main,
  },

  [`& .${classes.snackbarError}`]: {
    backgroundColor: theme.palette.error.main,
  },

  [`& .${classes.icon}`]: {
    fontSize: 22,
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(1, 0),
    display: "flex",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  color: theme.palette.innoBlue.contrastText,
  backgroundColor: theme.palette.innoBlue.main,
  transition: "all 0.5s ease",
  "&:hover": {
    color: theme.palette.innoBlue.dark,
    backgroundColor: theme.palette.innoBlue.light,
  },
}));
