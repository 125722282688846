import { useState, useEffect } from "react";
import {
  makeYearsArray,
  getJoursFeries,
  getOpeningHalfDayStartDate,
  getOpeningHalfDayEndDate,
} from "../util/dates";
import { openingDays } from "../util/openingHoursDays";
import { OpeningHoursDay } from "../util/types";

const years = makeYearsArray();
const exceptionnalClosures = years.reduce((acc: string[], year: number) => {
  acc = [...acc, ...getJoursFeries(year)];
  return acc;
}, []);

const useOpeningDayHours = () => {
  const [date, setDate] = useState(new Date());

  const tickTack = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerID = setInterval(() => tickTack(), 60000);
    return () => {
      clearInterval(timerID);
    };
  });

  const [currentDay, setCurrentDay] = useState<OpeningHoursDay | null>(
    openingDays[date.getDay()] || null
  );
  const isExceptionnalClosure = () =>
    exceptionnalClosures.indexOf(date.toLocaleDateString()) !== -1;

  const isOutOfOpenningHours = (time: number) => {
    if (!currentDay) return true;
    const { morning, afternoon } = currentDay;
    const morningStart = getOpeningHalfDayStartDate(date, morning);
    const morningEnd = getOpeningHalfDayEndDate(date, morning);
    const afternoonStart = getOpeningHalfDayStartDate(date, afternoon);
    const afternoonEnd = getOpeningHalfDayEndDate(date, afternoon);

    return (
      time <= morningStart || // morning has end
      time >= afternoonEnd || // afternoon has end
      (time >= morningEnd && time <= afternoonStart) // between morning & afternoon
    );
  };

  const [isClosed, setIsClosed] = useState(
    isExceptionnalClosure() || isOutOfOpenningHours(date.getTime())
  );

  useEffect(() => {
    setIsClosed(
      isExceptionnalClosure() || isOutOfOpenningHours(date.getTime())
    );
    setCurrentDay(openingDays[date.getDay()] || null);
  }, [date]);

  return { currentDay, isClosed, openingDays };
};

export default useOpeningDayHours;
