import React from "react";
import Seo from "../components/Seo";
import Content from "../views/Contact/Content";
import Hero from "../views/Contact/Hero";

const ContactPage = () => {
  return (
    <React.Fragment>
      <Seo title="Nous contacter" />
      <Hero />
      <Content />
    </React.Fragment>
  );
};

export default ContactPage;
