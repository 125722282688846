import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import HeroSubtitle from "../../../components/HeroSubtitle";
import HeroTitle from "../../../components/HeroTitle";
import { globalClasses } from "../../../components/layout/MainLayout/styles";
import { LEGALS_HERO_SECTION } from "../../../util/constants";
import { StyledSectionWrapper } from "./styles";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      sectionId={LEGALS_HERO_SECTION}
      className={globalClasses.innoBlueGradient}
    >
      <Container>
        <Grid
          item
          sm={12}
          justifyContent={isMobile ? "center" : "flex-start"}
          container
          direction="column"
        >
          <HeroTitle>Nous contacter</HeroTitle>
          <HeroSubtitle>
            Nos solutions vous intéresse ? Vous avez des questions ? Vous
            aimeriez bénéficier d'une démonstration de nos solutions ?<br />{" "}
            N'hésitez pas à nous contacter, nous nous ferons un plaisir de
            revenir vers vous pour vous renseigner ou pour vous aider.
          </HeroSubtitle>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Hero;
