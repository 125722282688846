import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "ContactContent";

export const classes = {
  root: `${PREFIX}-root`,
  column: `${PREFIX}-column`,
  title: `${PREFIX}-title`,
  marginBottom: `${PREFIX}-marginBottom`,
  imageWrapper: `${PREFIX}-imageWrapper`,
  image: `${PREFIX}-image`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.column}`]: {
    "&:first-of-type": {
      paddingRight: theme.spacing(3),
    },
    "&:last-child": {
      paddingLeft: theme.spacing(3),
    },
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
  },

  [`& .${classes.marginBottom}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.imageWrapper}`]: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.image}`]: {
    width: "75%",
    height: "auto",
  },
}));
