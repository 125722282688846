import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import ReCaptcha from "react-google-recaptcha";
import { MdCheckCircle, MdError } from "react-icons/md";
import { classes, StyledButton, StyledForm } from "./styles";
import { FormData, SnackbarContent } from "./types";

const RECAPTCHA_KEY = "6LcmvcocAAAAADBl2oR6ZZgeCPsKFp40-1Of-jvJ";

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const INITIAL_VALUES: FormData = {
  email: "",
  firstName: "",
  lastName: "",
  message: "",
  phone: "",
  structure: "",
  "bot-field": "",
};

const SUCCESS_SNACKBAR: SnackbarContent = {
  icon: MdCheckCircle,
  message: "Votre message a bien été envoyé !",
  success: true,
};

const ERROR_SNACKBAR: SnackbarContent = {
  icon: MdError,
  message:
    "Une erreur est survenue, votre message n'a pas été envoyé, veuillez réessayer.",
  success: false,
};

const Form = () => {
  const [formData, setFormData] = useState<FormData>(INITIAL_VALUES);
  const [snackbar, setSnackbar] = useState<SnackbarContent | null>(null);
  const [disabledSubmit, setDisabledSubmit] = React.useState(true);
  const recaptchaRef = React.createRef<any>();

  const validateForm = (data: FormData) => {
    const requiredFields = Object.keys(data).filter(
      (k) => !["bot-field", "message"].includes(k)
    );
    return requiredFields.every((field) => data[field]);
  };

  const updateValidateForm = useCallback(
    (captchaToken: any) => {
      if (!Boolean(captchaToken) || !validateForm(formData))
        setDisabledSubmit(true);
      else setDisabledSubmit(false);
    },
    [formData]
  );

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateValidateForm(recaptchaRef.current.getValue());
  }, [formData]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          "g-recaptcha-response": recaptchaValue,
          ...formData,
        }),
      });

      if (response.status) {
        if (response.status < 200 || response.status >= 300) throw response;
        else setSnackbar(SUCCESS_SNACKBAR);
      }
    } catch (error) {
      setSnackbar(ERROR_SNACKBAR);
    }
  };

  const handleSnackbarClose =
    (success?: boolean) => (event?: React.SyntheticEvent, reason?: string) => {
      if (success === undefined) return;
      if (reason === "clickaway") return;
      success && setFormData(INITIAL_VALUES);
      setSnackbar(null);
    };

  const renderIcon = useCallback((item: SnackbarContent) => {
    const { icon: Icon } = item;
    return <Icon fontSize="inherit" />;
  }, []);

  return (
    <React.Fragment>
      <StyledForm
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        {/* Netlify form submission needs */}
        <input type="hidden" name="contact" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              onChange={handleChange}
              value={formData["bot-field"]}
            />
          </label>
        </p>

        <TextField
          name="structure"
          label="Nom de la structure"
          required
          className={classes.gutters}
          onChange={handleChange}
          value={formData["structure"]}
        />
        <TextField
          name="lastName"
          label="Nom"
          required
          className={classes.gutters}
          onChange={handleChange}
          value={formData["lastName"]}
        />
        <TextField
          name="firstName"
          label="Prénom"
          required
          className={classes.gutters}
          onChange={handleChange}
          value={formData["firstName"]}
        />
        <TextField
          name="phone"
          label="Téléphone"
          required
          className={classes.gutters}
          onChange={handleChange}
          value={formData["phone"]}
        />
        <TextField
          name="email"
          label="E-mail"
          required
          className={classes.gutters}
          onChange={handleChange}
          value={formData["email"]}
        />
        <TextField
          name="message"
          label="Message"
          multiline
          minRows={10}
          className={classes.gutters}
          onChange={handleChange}
          value={formData["message"]}
        />
        <ReCaptcha
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          size="normal"
          onChange={(token) => updateValidateForm(token)}
          onExpired={() => setDisabledSubmit(true)}
          onErrored={() => setDisabledSubmit(true)}
        />
        <StyledButton
          type="submit"
          aria-label="Envoyer"
          variant="contained"
          size="large"
          disabled={disabledSubmit}
        >
          Envoyer
        </StyledButton>
        <Snackbar
          open={Boolean(snackbar)}
          autoHideDuration={5000}
          onClose={handleSnackbarClose(snackbar?.success)}
          TransitionComponent={Slide}
          classes={{ root: classes.snackbarRoot }}
        >
          {snackbar ? (
            <Paper
              className={clsx(classes.snackbarPaper, {
                [classes.snackbarError]: !snackbar.success,
                [classes.snackbarSuccess]: snackbar.success,
              })}
            >
              <div className={classes.icon}>{renderIcon(snackbar)}</div>
              <div>{snackbar.message}</div>
            </Paper>
          ) : (
            <div></div>
          )}
        </Snackbar>
      </StyledForm>
    </React.Fragment>
  );
};

export default Form;
